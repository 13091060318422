export function applyTransform(element, offsetX, offsetY, velocity) {
    if (offsetY >= element.offsetHeight * 3) {
        offsetY = element.offsetHeight * 3;
    }
    element.style.transform = `translate3d(${offsetX}, ${-Math.max(0, offsetY * velocity)}px, 0)`;
}

export function applyOpacity(element, offset) {
    element.style.opacity = Math.max(0, (100 - offset * 0.9) / 100);
}
